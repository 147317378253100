var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('vue-easy-lightbox',{attrs:{"visible":_vm.lightboxVisible,"imgs":_vm.images},on:{"hide":_vm.closeLightBox}}),_c('CommonAlertDialog',{attrs:{"show":_vm.showAlert,"item":_vm.alertItem},on:{"onCloseAlert":_vm.onCloseAlert}}),_c('CommodityFilter',{on:{"onFilterChange":_vm.onFilterChange}}),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.createItem()}}},[_c('v-icon',[_vm._v("mdi-account-plus")]),_vm._v(" "+_vm._s(_vm.$t("create"))+" ")],1)],1),_c('CommodityDialog',{attrs:{"show":_vm.showDialog,"action":_vm.action,"item":_vm.dialogItem,"new_customs_type":_vm.new_customs_type,"new_deliver_type":_vm.new_deliver_type},on:{"onClose":_vm.onCloseEvent,"onSave":_vm.onSaveEvent,"onCreateCustomsType":_vm.createCustomsType,"onCreateDeliverType":_vm.createDeliverType}}),_c('CommodityImageDialog',{attrs:{"show":_vm.showDialog2,"action":_vm.action,"item":_vm.dialogItem2,"images":_vm.images},on:{"deleteCommodityImage":_vm.deleteCommodityImage,"updateCommodityCover":_vm.updateCommodityCover,"onClose":_vm.onDialogClose2,"onSave":_vm.createCommodityImageEvent}}),_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-clipboard-text","title":_vm.$t('commodity.list')}},[_c('v-data-table',{attrs:{"headers":_vm.showHeaders,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"footer-props":_vm.footerProps,"multi-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"show-first-last-page":true,"show-current-page":true,"first-icon":"mdi-chevron-double-left","last-icon":"mdi-chevron-double-right","prev-icon":"mdi-chevron-left","next-icon":"mdi-chevron-right","items-per-page-options":[20, 50, 100]},on:{"update:options":updateOptions}})]}},{key:"header",fn:function(){return undefined},proxy:true},{key:"item.deliver_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_deliver_type_name(item))+" ")]}},{key:"item.customs_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_customs_type_name(item))+" ")]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_customer_name(item))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"large":""},on:{"click":function($event){return _vm.editItem(item)}}},on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("edit")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"large":""},on:{"click":function($event){_vm.disableItem(item,_vm.$t('commodity.commodity'),item.name)}}},on),[_vm._v("mdi-block-helper")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("disable")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"large":""},on:{"click":function($event){return _vm.editCommodiyImages(item)}}},on),[_vm._v("mdi-tooltip-image")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("edit"))+_vm._s(_vm.$t("commodity.image")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"large":"","color":item.image?'green':'',"disabled":item.image?false:true},on:{"click":function($event){return _vm.viewCommodiyImages(item)}}},on),[_vm._v("mdi-image")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("view"))+_vm._s(_vm.$t("commodity.image")))])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }