<template>
  <v-container fluid>
    <vue-easy-lightbox
      :visible="lightboxVisible"
      :imgs="images"
      @hide="closeLightBox"
    ></vue-easy-lightbox>
    <CommonAlertDialog
      :show="showAlert"
      :item="alertItem"
      v-on:onCloseAlert="onCloseAlert"
    >
    </CommonAlertDialog>
    <CommodityFilter v-on:onFilterChange="onFilterChange"></CommodityFilter>
    <v-col class="text-right">
      <v-btn
        color="primary"
        dark
        class="mb-2"
        @click="createItem()"
      >
        <v-icon>mdi-account-plus</v-icon>
        {{ $t("create") }}
      </v-btn>
    </v-col>
    <CommodityDialog
      :show="showDialog"
      :action="action"
      :item="dialogItem"
      :new_customs_type="new_customs_type"
      :new_deliver_type="new_deliver_type"
      v-on:onClose="onCloseEvent"
      v-on:onSave="onSaveEvent"
      v-on:onCreateCustomsType="createCustomsType"
      v-on:onCreateDeliverType="createDeliverType"
    ></CommodityDialog>
    <CommodityImageDialog
      :show="showDialog2"
      :action="action"
      :item="dialogItem2"
      :images="images"
      v-on:deleteCommodityImage="deleteCommodityImage"
      v-on:updateCommodityCover="updateCommodityCover"
      v-on:onClose="onDialogClose2"
      v-on:onSave="createCommodityImageEvent"
    ></CommodityImageDialog>
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('commodity.list')"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="showHeaders"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        multi-sort
      >
        <template v-slot:top="{pagination, options, updateOptions}">
          <v-data-footer 
            :pagination="pagination" 
            :options="options"
            @update:options="updateOptions"
            :show-first-last-page="true"
            :show-current-page="true"
            first-icon="mdi-chevron-double-left"
            last-icon="mdi-chevron-double-right"
            prev-icon="mdi-chevron-left"
            next-icon="mdi-chevron-right"
            :items-per-page-options="[20, 50, 100]"
          />
        </template>
        <template v-slot:header>
        </template>
        <template v-slot:item.deliver_type="{ item }">
          {{ get_deliver_type_name(item) }}
        </template>
        <template v-slot:item.customs_type="{ item }">
          {{ get_customs_type_name(item) }}
        </template>
        <template v-slot:item.customer="{ item }">
          {{ get_customer_name(item) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon large class="mr-2" v-on="on" @click="editItem(item)"
                >mdi-pencil</v-icon
              >
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon large class="mr-2" v-on="on" @click="disableItem(item,$t('commodity.commodity'),item.name)"
                >mdi-block-helper</v-icon
              >
            </template>
            <span>{{ $t("disable") }}</span>
          </v-tooltip>
          <v-tooltip
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-icon
                large
                class="mr-2"
                v-on="on"
                @click="editCommodiyImages(item)"
              >mdi-tooltip-image</v-icon>
            </template>
            <span>{{ $t("edit") }}{{ $t("commodity.image") }}</span>
          </v-tooltip>
          <v-tooltip
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-icon
                large
                class="mr-2"
                v-on="on"
                :color="item.image?'green':''"
                :disabled="item.image?false:true"
                @click="viewCommodiyImages(item)"
              >mdi-image</v-icon>
            </template>
            <span>{{ $t("view") }}{{ $t("commodity.image") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import CommonAlertDialog from "../dialogs/CommonAlertDialog";
import CommodityDialog from "../dialogs/CommodityDialog"
import CommodityFilter from "../filters/CommodityFilter"
import BaseTable from "./BaseTable.vue";
import { action_items } from "@/definition.js";
import VueEasyLightbox from 'vue-easy-lightbox'
import CommodityImageDialog from "../dialogs/CommodityImageDialog";
export default {
  data: vm => ({
    url: "/commodity/commodity/",
    headersMap: {
      id:{
        sortable: true,
        text: vm.$i18n.t("ID"),
        value: "id",
      },
      name:{
        sortable: true,
        text: vm.$i18n.t("commodity.name"),
        value: "name",
      },
      commodity_number:{
        sortable: true,
        text: vm.$i18n.t("commodity.number"),
        value: "commodity_number",
      },
      deliver_type_tc:{
        sortable: true,
        text: vm.$i18n.t("commodity.deliver-type"),
        value: "deliver_type.name_tc",
      },
      deliver_type_sc:{
        sortable: true,
        text: vm.$i18n.t("commodity.deliver-type"),
        value: "deliver_type.name_sc",
      },
      customs_type_tc:{
        sortable: true,
        text: vm.$i18n.t("commodity.customs-type"),
        value: "customs_type.name_tc",
      },
      customs_type_sc:{
        sortable: true,
        text: vm.$i18n.t("commodity.customs-type"),
        value: "customs_type.name_sc",
      },
      supplier_tc:{
        sortable: true,
        text: vm.$i18n.t("supplier.supplier"),
        value: "supplier.name_tc",
      },
      supplier_sc:{
        sortable: true,
        text: vm.$i18n.t("supplier.supplier"),
        value: "supplier.name_sc",
      },
      customer_tc:{
        sortable: true,
        text: vm.$i18n.t("customer.customer"),
        value: "customer.name_tc",
      },
      customer_sc:{
        sortable: true,
        text: vm.$i18n.t("customer.customer"),
        value: "customer.name_sc",
      },
      actions:{
        sortable: false,
        text: vm.$i18n.t("actions"),
        value: "actions",
      }
    },
    tcHeaders: ['id', 'name','commodity_number', 'customs_type_tc','supplier_tc','customer_tc',  'actions'],
    scHeaders: ['id', 'name','commodity_number', 'customs_type_sc','supplier_sc','customer_sc',  'actions'],
    action_items: action_items,
    new_customs_type: {},
    new_deliver_type: {},
    lightboxVisible: false,
    image_ids: [],
    images: [],
    showDialog2: false
  }),
  mixins: [BaseTable],
  computed: {},
  components: {
    CommodityDialog,
    CommodityFilter,
    CommonAlertDialog,
    VueEasyLightbox,
    CommodityImageDialog
  },
  methods: {
    createItem() {
      this.dialogItem = this._.cloneDeep(this.defaultItem);
      this.action = this.action_items.create;
      this.showDialog = true;
    },
    editItem(item) {
      this.orig_dialogItem = this._.cloneDeep(item);
      this.dialogItem = this._.cloneDeep(item);
      console.log('this.dialogItem',this.dialogItem)
      this.dialogItem.cover = item.image ? item.image.id : null
      // this.dialogItem.customs_type = item.customs_type.id
      // this.dialogItem.deliver_type = item.deliver_type.id
      this.image_ids = item.images
      this.action = action_items.edit;
      this.showDialog = true;
    },
    updateImages(){
      if (this.image_ids.length>0) {
        var params = {'id__in':this.image_ids}
        this.getApi("/document/document/",this.setAllImages,false,params,{},{})
      }else {
        this.images = []
      }
      
    },
    setAllImages(data){
      this.images = []
      data.results.forEach(img=>{
        this.images.push({
          id: img.id,
          title: img.name,
          src: img.file
        })
      })
    },
    get_customs_type_name(item){
      return item.customs_type?this.getHanText(item.customs_type,"name"):""
    },
    get_deliver_type_name(item){
      return item.deliver_type?this.getHanText(item.deliver_type,"name"):""
    },
    
    get_customer_name(item){
      return item.customer?this.getHanText(item.customer,"name"):""
    },
    createCustomsType(data){
      this.postApi(data, "/commodity/customs-type/", this.setNewCustomsType);
    },
    setNewCustomsType(action, responseData) {
      this.new_customs_type = responseData
    },
    createDeliverType(data){
      this.postApi(data, "/commodity/deliver-type/", this.setNewDeliverType);
    },
    setNewDeliverType(action, responseData) {
      this.new_deliver_type = responseData
    },
    viewCommodiyImages(item){
      var extra = JSON.parse(item.extra)
      this.getCommodityImages(extra['images'])
    },
    getCommodityImages(images){
      var params = {'id__in':images}
      this.getApi("/document/document/",this.setCommodityImages,false,params)
    },
    setCommodityImages(data){
      this.images = []
      data.results.forEach(img=>{
        this.images.push({
          title: img.name,
          src: img.file
        })
      })
      this.lightboxVisible = true;
    },
    closeLightBox() {
      this.lightboxVisible = false;
    },
    createCommodityImageEvent(commodity_id,image_binary){
      console.log('createCommodityImageEvent',commodity_id,image_binary)
      const data = {}
      data['commodity'] = commodity_id
      data['image_binary'] = image_binary
      this.imageCreateHandler(data,this.action_items.create)
    },
    imageCreateHandler(data,action){
      const currentObj = this;
      const url = process.env.VUE_APP_SERVER_URL + "/document/document/multi_create/";
      const formData = new FormData();
      data['image_binary'].forEach(image=>{
        formData.append("uploaded_images", image);  
      })
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "JWT " + this.token
        }
      };
      this.axios
        .post(url, formData, config)
        .then(function(response) {
          var images = response.data.images
          currentObj.createCommodityImage(data['commodity'],images)
        })
        .catch(function(error) {
          console.log(error.response);
          if (currentObj.allowAlert) {
            currentObj.alertItem = error.response;
            currentObj.showAlert = true;
          }
          currentObj.loading = false;
        });
    },
    createCommodityImage(commodity_id,new_images){
      // console.log('createCommodityImage',commodity_id,new_images)
      this.postApi({new_images:new_images},"/commodity/commodity/"+commodity_id+"/addImage/",this.refreshImage)
    },
    deleteCommodityImage(commodity_id,image_id){
      // console.log('deleteCommodityImage',commodity_id,image_id)
      this.postApi({image_id:image_id},"/commodity/commodity/"+commodity_id+"/deleteImage/",this.refreshImage)
    },
    refreshImage(action, data){
      // console.log('refreshImage',data)
      this.dialogItem2.cover = data.image ? data.image.id : null
      this.image_ids = data.images
    },
    updateCommodityCover(commodity_id,image_id){
      // console.log('updateCommodityCover',commodity_id,image_id)
      this.postApi({image_id:image_id},"/commodity/commodity/"+commodity_id+"/changeCover/",this.refreshImage)
    },
    editCommodiyImages(item){
      this.orig_dialogItem2 = this._.cloneDeep(item);
      this.dialogItem2 = this._.cloneDeep(item);
      this.dialogItem2.cover = item.image ? item.image.id : null
      this.image_ids = item.images?item.images:[]
      this.action = action_items.edit;
      this.showDialog2 = true
    },
    onDialogClose2(){
      this.showDialog2 = false
    },
  },
  mounted() {
    this.getParams['enable'] = true
  },
  watch: {
    image_ids(val) {
      // console.log('image_ids',val)
      this.updateImages()
    },
  },
}
</script>
