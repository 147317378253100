var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"commodity.filter","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('commodity.name'),"clearable":""},model:{value:(_vm.filterItem.name__istartswith),callback:function ($$v) {_vm.$set(_vm.filterItem, "name__istartswith", $$v)},expression:"filterItem.name__istartswith"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('commodity.number'),"clearable":""},model:{value:(_vm.filterItem.commodity_number__istartswith),callback:function ($$v) {_vm.$set(_vm.filterItem, "commodity_number__istartswith", $$v)},expression:"filterItem.commodity_number__istartswith"}})],1),_c('v-col')],1),_c('v-row',[_c('v-col',[_c('CommonAutocomplete',{attrs:{"label":_vm.$t('commodity.deliver-type'),"placeholder":'',"url":"/commodity/deliver-type/","item_text":_vm.nameField,"item_value":"id","query_field":_vm.nameFieldFilter,"extra_filter":{},"filter_fields":{
          
        }},model:{value:(_vm.filterItem.deliver_type__id),callback:function ($$v) {_vm.$set(_vm.filterItem, "deliver_type__id", $$v)},expression:"filterItem.deliver_type__id"}})],1),_c('v-col',[_c('CommonAutocomplete',{attrs:{"label":_vm.$t('commodity.customs-type'),"placeholder":'',"url":"/commodity/customs-type/","item_text":_vm.nameField,"item_value":"id","query_field":_vm.nameFieldFilter,"extra_filter":{},"filter_fields":{
          
        }},model:{value:(_vm.filterItem.customs_type__id),callback:function ($$v) {_vm.$set(_vm.filterItem, "customs_type__id", $$v)},expression:"filterItem.customs_type__id"}})],1),_c('v-col')],1),_c('v-row',[_c('v-col',[_c('CommonAutocomplete',{attrs:{"label":_vm.$t('supplier.supplier'),"placeholder":'',"url":"/supplier/supplier/","item_text":_vm.nameField,"item_value":"id","query_field":_vm.nameFieldFilter,"extra_filter":{},"filter_fields":{
          
        }},model:{value:(_vm.filterItem.supplier__id),callback:function ($$v) {_vm.$set(_vm.filterItem, "supplier__id", $$v)},expression:"filterItem.supplier__id"}})],1),_c('v-col',[_c('CommonAutocomplete',{attrs:{"label":_vm.$t('customer.customer'),"placeholder":'',"url":"/customer/customer/","item_text":_vm.nameField,"item_value":"id","query_field":_vm.nameFieldFilter,"extra_filter":_vm.filterItem,"filter_fields":{
          
        }},model:{value:(_vm.filterItem.customer__id),callback:function ($$v) {_vm.$set(_vm.filterItem, "customer__id", $$v)},expression:"filterItem.customer__id"}})],1),_c('v-col',[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.onChange}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-magnify")]),_vm._v(" "+_vm._s(_vm.$t("search"))+" ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }