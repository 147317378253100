<template>
  <v-container
    id="commodity.filter"
    fluid
    tag="section"
  >
    <v-row>
      <v-col>
        <v-text-field
          v-model="filterItem.name__istartswith"
          :label="$t('commodity.name')"
          clearable
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="filterItem.commodity_number__istartswith"
          :label="$t('commodity.number')"
          clearable
        ></v-text-field>
      </v-col>
      <v-col></v-col>
    </v-row>
    <v-row>
      <v-col>
        <CommonAutocomplete
          v-model="filterItem.deliver_type__id"
          :label="$t('commodity.deliver-type')"
          :placeholder="''"
          url="/commodity/deliver-type/"
          :item_text="nameField"
          item_value="id"
          :query_field="nameFieldFilter"
          :extra_filter="{}"
          :filter_fields="{
            
          }"
        ></CommonAutocomplete>
      </v-col>
      <v-col>
        <CommonAutocomplete
          v-model="filterItem.customs_type__id"
          :label="$t('commodity.customs-type')"
          :placeholder="''"
          url="/commodity/customs-type/"
          :item_text="nameField"
          item_value="id"
          :query_field="nameFieldFilter"
          :extra_filter="{}"
          :filter_fields="{
            
          }"
        ></CommonAutocomplete>
      </v-col>
      <v-col></v-col>
    </v-row>
    <v-row>
      <v-col>
        <CommonAutocomplete
          v-model="filterItem.supplier__id"
          :label="$t('supplier.supplier')"
          :placeholder="''"
          url="/supplier/supplier/"
          :item_text="nameField"
          item_value="id"
          :query_field="nameFieldFilter"
          :extra_filter="{}"
          :filter_fields="{
            
          }"
        ></CommonAutocomplete>
      </v-col>
      <v-col>
        <CommonAutocomplete
          v-model="filterItem.customer__id"
          :label="$t('customer.customer')"
          :placeholder="''"
          url="/customer/customer/"
          :item_text="nameField"
          item_value="id"
          :query_field="nameFieldFilter"
          :extra_filter="filterItem"
          :filter_fields="{
            
          }"
        ></CommonAutocomplete>
      </v-col>
      <v-col>
        <v-btn
          color="primary"
          dark
          class="mb-2"
          @click="onChange"
        >
          <v-icon left>mdi-magnify</v-icon>
          {{ $t("search") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import BaseFilter from "./BaseFilter";
import CommonAutocomplete from "./CommonAutocomplete";
export default {
  props: {},
  data: (vm) => ({}),
  computed: {},
  components: {
    CommonAutocomplete,
  },
  mixins: [BaseFilter],
  methods: {},
};
</script>
